import React, { useState } from 'react';
import './header-tabs.styles.scss'

import { Link, useNavigate } from 'react-router-dom'

const HeaderTabs = ({ tabs }) => {

	const navigate = useNavigate();
	const [ showTabSlider, setShowTabSlider ] = useState(false)

	function slideTabDown() {
		setShowTabSlider(!showTabSlider)
		const el = document.getElementById('header_mobile_tab_wrapper');
		if (!showTabSlider) {
			el.style.height = `${window.innerHeight}px`;
		} else {
			el.style.height = '0px';
		}
	}
	
	function goTo(path) {
		slideTabDown()
		navigate(path)	
	}
	
	return (
		<div>
			<div id='header_tabs' className='max_desktop_width'>
				{
					(tabs)
					?	
					tabs.map(tab => (
						<Link key={tab.id} id={tab.id} to={tab.href} className={`tab_div ${(tab.selected) ? tab.selected : 'no_selected'}`}>
							<div className='tab_title'>
								{tab.title}
							</div>
						</Link>
					))
					: ''
				}
				
				
				{
					(tabs)
					?
					<a className='hide' id='mtab_more' title='Show All Tabs' alt='Show All Tabs' onClick={() => slideTabDown()}>
						<img src='https://ilprincipals.org//framework/images/more_light.png' />
					</a>
					: ''
				}
			</div>
			<div id='header_mobile_tab_wrapper'>
				<div id='header_mobile_tab_nav'>
					{
						(tabs)
						?	
						tabs.map(tab => (
							<a key={tab.id} onClick={() => goTo(tab.href)} >
								<div className='tab_title'>
									{tab.title}
								</div>
							</a>
						))
						: ''
					}
					
				</div>
			</div>
		</div>
	)
}

export default HeaderTabs;