import React from 'react'

import ElnForm from './eln.form.json'
import IsbeForm from './isbe.form.json'

export const CombineForms = () => {
	const newObj = {
		eln: ElnForm,
		isbe: IsbeForm
	}

	return newObj;
}

export const FormTypes = {
	"standard": {
		id: "standard",
		comps: [
			"isbe"
		],
		title: "Standard ISBE Eval Only"
	},
	"eln": {
		id: "eln",
		comps: [
			"isbe",
			"eln"
		],
		title: "ELN Course Eval"
	},
    "coach": {
		id: "coach",
		comps: [
			"isbe",
			"coach"
		],
		title: "Coach Eval for Coaching and Mentoring"
	},
    "protege": {
		id: "protege",
		comps: [
			"isbe",
			"protege"
		],
		title: "Protege Eval for Coaching and Mentoring"
	},
}

export const Sources = {
	"bridge": {
		id: "bridge",
		title: "ELN Online Course",
		bgUrl: "",
		headIcon: "",
		titleColor: "",
		cat: "eln"
	},
    "zoomWebinar": {
        id: 'zoomWebinar',
        title: "Zoom Webinar",
		bgUrl: "",
		headIcon: "",
		titleColor: "",
		cat: "eln"
    },
	"aa": {
		id: "aa",
		title: "On-Demand Administrator Academy",
		bgUrl: "",
		headIcon: "",
		titleColor: "",
		cat: "ipa"
	},
    "coach": {
		id: "coach",
		title: "Coaching and Mentoring",
		bgUrl: "",
		headIcon: "",
		titleColor: "",
		cat: "ipa"
	},
	"mc": {
		id: "mc",
		title: "Micro-Credential",
		bgUrl: "",
		headIcon: "",
		titleColor: "",
		cat: "eln"
	}
}