import React, { useEffect, useState } from 'react'
import './header.styles.scss'

import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { auth } from '@/utils/firebase.utils'

import HeaderMain from './header-main.component'
import MobileSlider from './mobile-slider.component'
import { version } from '../../changelog-version'

const Header = ({ tabs, source }) => {

	const currentUser = useSelector(state => state.userData.currentUser)
	const profile = useSelector(state => state.userData.userProfile)
	const dbData = useSelector(state => state.userData.dbData)
	const [ showMobileSlider, setShowMobileSlider] = useState(false)
	const [ mobileLinks, setMobileLinks ] = useState([])
	const [ returnUrl, setReturnUrl ] = useState(null)
    const [ showDropdown, setShowDropdown ] = useState(false)
	const location = useLocation()

	useEffect(() => {
		var lastScrollTop = 0, delta = 10, min_y = 50;
	  	// window.addEventListener("scroll", (event) => {
		// 	var nowScrollTop = document.documentElement.scrollTop;
	    //     var bottom_ceiling = document.documentElement.scrollHeight - window.innerHeight - 5; // 5 is a little buffer
	    //     const headerFloater = document.getElementById('header_floater');
	    //     if (Math.abs(lastScrollTop - nowScrollTop) >= delta) {
	    //         if (nowScrollTop > lastScrollTop && nowScrollTop > min_y) {
	    //             headerFloater.classList.remove('header_floater_visible')
		// 			headerFloater.classList.add('header_floater_hidden');
	    //         } else if (nowScrollTop < bottom_ceiling) {
	    //             headerFloater.classList.add('header_floater_visible')
		// 			headerFloater.classList.remove('header_floater_hidden');
	    //         }
	    //         lastScrollTop = nowScrollTop;
	    //     }
		// });

		
	}, [])

	useEffect(() => {
		let href = window.location.origin
		// console.log(window.location)
		let encoded = encodeURIComponent(href  + `/sso/?token={token}&intendedurl=%2Fhome%2F`)
		// console.log(encoded)
		setReturnUrl(`https://eweb.ilprincipals.org/eweb/?Site=ipa&WebCode=LoginRequired&expires=yes&URL_success=${encoded}`)
	},[window, setReturnUrl])

	function showMobileMenu() {
		document('body').classList.add('noscroll');
        document.getElementById('header_floater_nav').slideDown('fast');
	}

	function slideDown(id) {
		setShowMobileSlider(!showMobileSlider)
		const element = document.getElementById(id)
		if (!showMobileSlider) {
			element.style.height = `${window.innerHeight}px`;
		} else {
			element.style.height = '0px';
		}
	}
	
	return (
		
		<header className='header'>
			{
				(source || !source) && 
				<>
					<MobileSlider id='main' slideDown={slideDown} mobileLinks={mobileLinks} />
					{
						(location.pathname.includes('/admin') || (location.pathname.includes('/home/') && (source === 'eln' || source === 'ipa'))) && 
						<div id='header_floater' className='unselectable'>
							<div id='header_floater_screen'></div>
							<div id='header_floater_inner'>
								<div id='header_floater_left'>
									<div id='header_floater_minilogo'>
										{/* <a href='/'>
											<img src='https://ilprincipals.org/images/main/IPA_minilogo_2022b.png' />
										</a> */}
									</div>
									{
										// <div id='header_floater_social'>
										// 	<a href='https://www.facebook.com/ilprincipals' target='_new'>
										// 		<img src='https://ilprincipals.org//framework/images/social/white/facebook.png' />
										// 	</a>
										// 	<a href='https://twitter.com/ilprincipals' target='_new'>
										// 		<img src='https://ilprincipals.org//framework/images/social/white/twitter.png' />
										// 	</a>
										// 	<a href='https://www.youtube.com/channel/UCRs_ei-q97RiDmb49o4rHcA' target='_new'>
										// 		<img src='https://ilprincipals.org//framework/images/social/white/youtube.png' />
										// 	</a>
										// </div>
									}
								</div>
								<div id='header_floater_right'>
									<div id='header_floater_nav'>
										{
											(location.pathname)
											?
											<>
												<div id='mnav_top'>
													<a id='mnav_close'>
														<img src='https://ilprincipals.org/framework/images/close_dark.svg' />
													</a>
													<div id='mnav_logo'>
														<a href='/'>
															<img src='https://ilprincipals.org/images/main/IPA_logo_green_2022.png' alt='Illinois Principals Association' title='Illinois Principals Association' />
														</a>
													</div>
													{
														// <div id='mnav_search'>
														// 	<form role='search' id='headerSearchMobile' method='get' action='/search/'>
														// 		<a id='mnav_search_submit' ></a>
														// 		<input type='text' value name='q' id='mnav_search_input' placeholder='Search' />
														// 	</form>
														// </div>
													}
												</div>
			
												{
													(currentUser)
													?
														<Link className='uk-link-reset' to='/home'>	
															<div className='menuitem allcaps' >
																<span className='menuitem_inner'>
																	Home
																</span>
															</div>
														</Link>
														: ''
												}
													{
														(dbData && dbData.Admin) 
														?
														<Link className='uk-link-reset' to='/admin'>	
															<div className='menuitem allcaps' >
																<span className='menuitem_inner'>
																	Admin
																</span>
															</div>
														</Link>
														
														: ''
													}
												
												<div className="menu_spacer"></div>
												
												{
													(currentUser)
													?
													// <Link className='uk-link-reset' to='/logout'>	
														<div className='menuitem allcaps' onClick={() => setShowDropdown(!showDropdown)}>
															<span className='menuitem_inner'>
																{currentUser.displayName}
															</span>
														</div>
													// </Link>
													:
													(returnUrl)	
													?
													<a className='uk-link-reset' href={returnUrl}>	
														<div className='allcaps menuitem ' >
															<span className='menuitem_inner'>
																Login
															</span>
														</div>
													</a>
													: ''
												}
												{
                                                    (showDropdown || !showDropdown) &&
                                                    <div id="dropdownMenuContainer" className={showDropdown ? 'active' : ''}>
                                                        <div className='dropdown-menu'>
                                                            <Link to="/logout">Logout</Link>
															<div className="g-space-1"></div>
															<div className='meta'>{version}</div>
                                                        </div>
                                                    </div>
                                                }
												
											
											</>
										: ''
										}
									</div>
									<div id='header_floater_hamburger'>
										<a onClick={() => slideDown('main')}>
											<img src='https://ilprincipals.org/images/main/hamburger_light.png' />
										</a>
									</div>
								</div>
							</div>
						
						</div>
					}
					<HeaderMain tabs={tabs} slideDown={slideDown} source={source} />
				</>
			}
		</header>
		
	)
}

export default Header;