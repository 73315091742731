import React, { useEffect, useState } from 'react'
import './landing-content.styles.scss'
import { Navigate, useParams, useNavigate } from 'react-router-dom'
import Header from '@/components/header/header.component'
import Footer from '@/components/footer/footer.component'
import { doc, getDoc } from "firebase/firestore";
import { db } from '@/utils/firebase.utils'
import { useSelector } from 'react-redux';
import eln_logo from '@/assets/elnlogo_wht.png';
import eln_logo_white from '@/assets/elnlogo_wht.png';

const LandingContent = () => {

	const navigate = useNavigate();
	const currentUser = useSelector(state => state.userData.currentUser);
	const profile = useSelector(state => state.userData.userProfile)
	const dbData = useSelector(state => state.userData.dbData)
	const { id } = useParams()
	const [ noId, setNoId ] = useState(false)
	const [ siteType, setSiteType ] = useState(null)

	useEffect(() => {
		console.log(id)
		if (!id) {
			setNoId(true)
			console.log('checking for id')
			return;
		}
	}, [])
	
	useEffect(() => {
		if (!id) {
			setNoId(true)
			return;
		}
		getEvalDoc(id);
	}, [id]);

	async function getEvalDoc(id) {
		const docRef = doc(db, 'evals', id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			console.log(docSnap.data())
			let evaluation = docSnap.data();
			if (evaluation.source == 'bridge' || evaluation.source == 'mc') {
				setSiteType('eln')
			} else {
				setSiteType('ipa')
			}
		} else {
		  	// doc.data() will be undefined in this case
			setNoId(true);
		  	console.log("No such document!");
		}
	}
	

	useEffect(() => {
		if (id && siteType) {
			let href = window.location.origin
			// console.log(window.location)
			let encoded = encodeURIComponent(href  + `/sso/?token={token}&intendedurl=%2Feval%2F${id}`)
			// console.log(encoded)
			window.open(`https://eweb.ilprincipals.org/eweb/?Site=${siteType}&WebCode=LoginRequired&expires=yes&URL_success=${encoded}`, '_self')

		}
		
	}, [id, siteType])

	function goToEvals(e) {
        console.log(e)
		if (currentUser && profile && dbData) {
			navigate(`/home/${e}`)
            console.log(e)
		} else {
			let href = window.location.origin
			// console.log(window.location)
			let encoded = encodeURIComponent(href  + `/sso/?token={token}&intendedurl=%2Fhome%2F${e}`)
			// console.log(encoded)
			window.open(`https://eweb.ilprincipals.org/eweb/?Site=ipa&WebCode=LoginRequired&expires=yes&URL_success=${encoded}`, '_self')
		}
		
	}
	
	return (
		<div className='landing-content'>
			<div className="index-header">
        		{
					(noId)
					? 
					<>
						<Header />
						<div className='g-height-40'></div>
						<div className='uk-container'>
							<h3>Please make sure you are using the correct link to the evaluation.<br />Click one of the options below to see your completed evaluations.</h3>

							<div className='cards'>
								<div className='g-card source-card ipa'  onClick={() => goToEvals('ipa')}>
									<table>
										<tbody>
											<tr>
												<td className='source-card-icon-td'>
													<img src={eln_logo} className='source-card-icon' />	
												</td>	
												<td className='source-card-text-td'>
													<h3>View My IPA Evaluations</h3>
												</td >
											</tr>
										</tbody>	
									</table>
								</div>
								<div className='g-card source-card eln' onClick={() => goToEvals('eln')}>
									<table>
										<tbody>
											<tr>
												<td className='source-card-icon-td'>
													<img src={eln_logo_white} className='source-card-icon' />	
												</td>	
												<td className='source-card-text-td'>
													<h3>View My ELN Evaluations</h3>
												</td >
											</tr>
										</tbody>	
									</table>
								</div>
							</div>
						</div>
						<div className='g-height-80'></div>
						<Footer />
					</>
					
					: ''
				}
		  	</div>
		</div>
	)
}

export default LandingContent