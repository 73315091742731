import React, { useEffect, useState } from 'react'
import './eval-page.styles.scss'

import { Routes, Route, Navigate } from 'react-router-dom'

import Header from '@/components/header/header.component'
// import Header from '@/components/header/header.component'
import HomeContent from '@/components/HOME/home-content/home-content.component'
import Footer from '@/components/footer/footer.component'
import { Sources } from '@/forms/formTypes'


const EvalPage = () => {

	const [ source, setSource ] = useState(null)

	useEffect(() => {
		const header = document.getElementsByClassName('header')[0]
		console.log('source', source)
		if (source && source !== 'eln' && source !== 'ipa') {
			const sourceCat = Sources[source].cat;
			header.classList.add(sourceCat)		
			setSource(sourceCat)
		} else if (source) {
			header.classList.add(source)
		} else {
			header.classList.remove('eln')
			header.classList.remove('ipa')
		}
		
		
	}, [source])
	
	return (
		<div className='homepage'>
			<Header source={source} />
			<Routes>
				<Route path='' element={<Navigate to="/" />} ></Route>
				<Route path='/:id' element={
					<div className='homepage-content g-container'>
						<HomeContent setSource={setSource} />
					</div>
				}></Route>
			</Routes>
			
			<Footer />
		</div>
	)
}

export default EvalPage