import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import './landing-page.styles.scss'

import { useSelector } from 'react-redux'

import LandingContent from '@/components/landing-content/landing-content.component'

const LandingPage = () => {

	const user = useSelector(state => state.userData.currentUser)
	const dbData = useSelector(state => state.userData.dbData)
	const profile = useSelector(state => state.userData.userProfile)
	
	return (
		<div className='landing-page'>
			<Routes>
				<Route path='' element={<LandingContent />}></Route>
				<Route path='/:id' element={<LandingContent />}></Route>
			</Routes>
		</div>
	)
}

export default LandingPage