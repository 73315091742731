import React from 'react'
import './mobile-slider.styles.scss'

import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

const MobileSlider = ({ id, slideDown, mobileLinks }) => {

	const dbData = useSelector(state => state.userData.dbData)
	const navigate = useNavigate()

	function goTo(path) {
		slideDown('main')
		navigate(path)
	}
	
	return (
		<div className='mobile-slider'>
			<div className="wrapper" id={id}>
				
    			<div className="slideMeDown">
					<div className="mnav_top">
						<div id='header_floater_hamburger'>
							<a onClick={() => slideDown('main')}>
								<img src="https://ilprincipals.org/framework/images/close_dark.svg"  />
							</a>
						</div>
						<img src="https://ilprincipals.org/images/main/IPA_logo_green_2022.png" alt="Illinois Principals Association" title="Illinois Principals Association" />
					</div>
					
					<a className='slider_menuitem_inner' onClick={() => goTo('/home')}>
						<div className='slider_menuitem allcaps'>
							Home
						</div>
					</a>
					
					
					{
						(dbData && dbData.Admin) 
						?
							<a className='slider_menuitem_inner' onClick={() => goTo('/admin')}>
								<div className='slider_menuitem allcaps'>
									Admin
								</div>
							</a>
						: ''
					}
					<div className="header_nav_mobile_bottom_line">
						<div id="mnav_social">
							<a href="https://www.facebook.com/ilprincipals" target="_new">
								<img src="https://ilprincipals.org/framework/images/social/color/facebook.png" />
							</a>
							<a href="https://twitter.com/ilprincipals" target="_new">
								<img src="https://ilprincipals.org/framework/images/social/color/twitter.png" />
							</a>
							<a href="https://www.youtube.com/channel/UCRs_ei-q97RiDmb49o4rHcA" target="_new">
								<img src="https://ilprincipals.org/framework/images/social/color/youtube.png" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MobileSlider;