import React from 'react'
import './inputs.styles.scss'

export const Input = ({ req, u_key, label, handleChange, cls, user, newData, type}) => {

	return (
		<div className="uk-margin">
			<label className='section-label'>{label}</label>
			{
				(req == 'true')
				? <p className='required'>* Required</p>
				: ''
			}
			<input className={`uk-input uk-form-small ${(cls) ? cls : ''}`} 
				value={(newData[u_key]) ? newData[u_key] : ''} 
				onChange={e => handleChange(u_key, e.target.value)} 
				type={(type) ? type : 'text'}
				id={u_key}
			/>
		</div>
	)
}

export const Textarea = ({ req, u_key, label, handleChange, cls, user, newData, type, rows }) => {

	return (
		<div className="uk-margin multiple form-box">
			<label className='section-label'>{label}</label>
			{
				(req == 'true')
				? <p className='required'>* Required</p>
				: ''
			}
			<textarea className={`uk-textarea uk-form-small ${(cls) ? cls : ''}`} 
				onChange={e => handleChange(u_key, e.target.value)} 
				type={(type) ? type : 'text'}
				id={u_key}
				rows={(rows) ? rows : '3'}
			></textarea>
		</div>
	)
}

export const Select = ({ req, u_key, label, options, handleChange, cls, user, newData}) => {

	return (
		<div className="uk-margin multiple form-box">
			<label >{label}</label>
			{
				(req == 'true')
				? <p className='required'>* Required</p>
				: ''
			}
			<div className='form-box'>
				<select className={`uk-select uk-form-small ${(cls) ? cls : ''}`} 
					onChange={e => handleChange(u_key, e.target.value)} 
					id={u_key}
				>
					<option value=''>Choose...</option>
					{				
						options.map(opt => (
							<option key={opt.key} value={opt.key}>{opt.text}</option>
						))
					}
				</select>
			</div>
		</div>
	)
}

export const Multi = ({ req, u_key, label, options, handleChange, cls, user, newData, checkAll }) => {

	return (
		<div className="uk-margin multiple">
			<label className='section-label'>{label}</label>
			{
				(req == 'true')
				? <p className='required'>* Required</p>
				: ''
			}
			<div className=''>
				<table>
					<tbody>
						{
							options.map(opt => (  // receives array of objects: {key, text}
								<tr key={opt.key}>
									<td className='check'>
										<input className={`uk-checkbox check-${u_key} ${(cls) ? cls : ''}`} type="checkbox" id={opt.key} onChange={(e) => handleChange(opt.key, e.target.checked)}  />
									</td>
									<td>{opt.text}</td>
								</tr>
							))
						}
					</tbody>
				</table>
				
			</div>
		</div>
	)
}