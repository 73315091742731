import React, { useEffect } from 'react'
import './sso.styles.scss'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { signInWithCustomToken, updateProfile, updateEmail } from "firebase/auth";
import { auth, UseCloudFunction } from '@/utils/firebase.utils'

import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setDbData, setUserProfile } from '@/state/slices/userSlice';

import GetProfileData from '@/utils/getProfile'

import Header from '@/components/header/header.component'


const SSO = () => {

 	// const { token } = useParams()
	const [searchParams, setSearchParams] = useSearchParams();
	const token = searchParams.get("token")
	const intURL = searchParams.get("intendedurl")
  	const navigate = useNavigate()
	const dispatch = useDispatch()

 	useEffect(() => {
		async function saveData() {
			// console.log(token, intURL)
			const authData = await UseCloudFunction('tokenAuth',{'token':token})
			// console.log(authData)
			signInWithCustomToken(auth, authData.token)
			.then(async (userCredential) => {
				// Signed in
				updateProfile(auth.currentUser, {
					displayName: authData.displayName,
				})
				// console.log(authData.email)
				updateEmail(auth.currentUser, authData.email)
				let twentyMinutesLater = new Date();
				twentyMinutesLater.setMinutes(twentyMinutesLater.getMinutes() + 20);
				dispatch(setDbData(authData.fullData))
				console.log('calling for profile data')
				const profileData = await GetProfileData(authData.fullData.Individual.ind_cst_key)
				dispatch(setUserProfile(profileData))
				navigate(intURL)
			})
			.catch((error) => {
				var errorCode = error.code;
				var errorMessage = error.message;
				// ...
			});
		}
		saveData();
		
    // navigate('/home')
	}, [])

	  
	return (
		<div className='sso-page'>
			<Header />
  			<div className='text'>
				<div data-uk-spinner="ratio: 3"></div><br/>
				Loading...
			</div>
		</div>
	)
}

export default SSO