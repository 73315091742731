import React, { useState, useEffect, useRef } from 'react';
import './create-eval-form.styles.scss'

import { useSelector, useDispatch } from 'react-redux'
import { setEvalList } from '@/state/slices/listsSlice';

import { FormTypes, Sources } from '@/forms/formTypes'
import UIkit from 'uikit'

import { UseCloudFunction } from '@/utils/firebase.utils'
import { RandomString } from '@/utils/randomString'

const CreateEvalForm = () => {

	const evalList = useSelector(state => state.listsData.evalList)
	const [ formData, setFormData ] = useState({'hours': 0, 'cpdu': false});
	const [ resultList, setResultList ] = useState(null);
	const [ nextUrl, setNextUrl ] = useState(null);
	const [ formComplete, setFormComplete ] = useState(false);
    const [ loading, setLoading ] = useState(false);
	const stateRef = useRef()
	stateRef.current = formData;
	const dispatch = useDispatch()	

	function saveData(value, key) {
		console.log(value)
        if (key === 'source') {
            setResultList(null)
            const initial = {...{[key]: value, 'hours': 0, 'cpdu': false}}
            if (value === 'custom'){
                setFormData({...initial, ...{'custom': true}});
            } else {
                setFormData(initial);
            }
            return;
        }
		if (value === '' && key !== 'cpdu') {
            if (key === 'title' || key === 'description') {
                delete formData.item[key]
            } else {
    			delete formData[key];
            }
			setFormData({...formData})
			return;
		}
		if (key === 'hours') {
			value = Number(value)
		}
        
        if (key === 'title' || key === 'description') {
            const newValue = {...formData.item, ...{[key]: value}}
            const newKey = 'item';
            setFormData({...formData, ...{[newKey]: newValue}});
            return;
        }
		setFormData({...formData, ...{[key]: value}});
		// console.log(formData)
	}

	useEffect(() => {
		// console.log(stateRef.current)
		if (stateRef.current && Object.values(stateRef.current).length >= 6) {
			setFormComplete(true)
		} else {
			setFormComplete(false)
		}
	}, [formData])

	async function search() {
        setLoading(true);
		delete formData.comps
		delete formData.item
		delete formData.itemId
		delete formData.type
		setFormData({...formData, ...{
			'cpdu': false,
			'hours': 0
		}});
		setResultList(null)
		const source = formData.source;
		if (source === 'bridge') {
            const text = document.getElementById('search-text').value;
		    console.log(text)
			const url = `https://eln.bridgeapp.com/api/learner/library_items?search=${text}&sort=title` 
			const res = await UseCloudFunction('getBridgeCourses', { 'url': url })
			console.log(res)
			if (res.meta.next) {
				setNextUrl(res.meta.next)
			} else {
				setNextUrl(null)
			}
			setResultList(res.library_items)
            setLoading(false);
		} else if (source === 'aa') {
            let text = 'AA';
		    console.log(text)
			const url = `https://eln.bridgeapp.com/api/author/programs?search=${text}&sort=newest` 
			const res = await UseCloudFunction('getBridgePrograms', { 'url': url })
			console.log(res)
			if (res.meta.next) {
				setNextUrl(res.meta.next)
			} else {
				setNextUrl(null)
			}
			setResultList(res.programs) 
            setLoading(false);
        } else if (source === 'zoomWebinar') {
            let url = `https://api.zoom.us/v2/users/arlin@ilprincipals.org/webinars`

            let res = await UseCloudFunction('getZoomWebinars', { 'url': url })
			console.log(res)
            if (res.results.nextPageToken) {
                setNextUrl(res.results.nextPageToken)
			} else {
				setNextUrl(null)
			}
            let arr = []
            for (let web of res.results.webinars) {
                arr.push({
                    'id': web.id, 
                    'title': web.topic, 
                    'date': new Date(web.start_time).getTime(),
                    'description': web.agenda
                })
            }
            
            url = `https://api.zoom.us/v2/users/arlin@ilprincipals.org/meetings`

            res = await UseCloudFunction('getZoomWebinars', { 'url': url })
			console.log(res)
            if (res.results.nextPageToken) {
                setNextUrl(res.results.nextPageToken)
			} else {
				setNextUrl(null)
			}

            for (let web of res.results.meetings) {
                arr.push({
                    'id': web.id, 
                    'title': web.topic, 
                    'date': new Date(web.start_time).getTime(),
                    'description': web.agenda
                })
            }
            arr.sort((a,b) => a.date < b.date ? 1 : -1)
            setResultList(arr)
            setLoading(false);
        } else if (source === 'mc') {
            let res = await UseCloudFunction('getMCs', { 'url': 'data' })
			console.log(res)
            let arr = [];
            for (let mc of res.list) {
                arr.push({
                    'id': mc.mer_prd_key, 
                    'title': mc.prd_name, 
                    'date': new Date(mc.prd_add_date).getTime(),
                    'description': mc.prd_description
                })
            }
            arr.sort((a,b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)
            setResultList(arr)
            setLoading(false);
        }
	}

	async function getMoreResults() {
        setLoading(true)
		const source = formData.source;
		if (source === 'bridge') {
			const res = await UseCloudFunction('getBridgeCourses', { 'url': nextUrl })
			console.log(res)
			if (res.meta.next) {
				setNextUrl(res.meta.next)
			} else {
				setNextUrl(null)
			}
			setResultList([...resultList, ...res.library_items])
            setLoading(false)
		}
        if (source === 'aa') {
			const res = await UseCloudFunction('getBridgePrograms', { 'url': nextUrl })
			console.log(res)
			if (res.meta.next) {
				setNextUrl(res.meta.next)
			} else {
				setNextUrl(null)
			}
			setResultList([...resultList, ...res.programs])
            setLoading(false)
		}
	}

	function chooseItem(id) {
		
		const items = document.getElementsByClassName('result-list-item')
		for (let i of items) {
			i.classList.remove('selected')
		}
		document.getElementById(id).classList.add('selected')
		let item;
		for (let r of resultList) {
			if (`${formData.source}-${r.id}` === `${formData.source}-${id}`) {
				item = r
			}
		}
		setFormData({...formData, ...{
			'item': item,
			'itemId': `${formData.source}-${id}`
		}});
	}

	async function saveEvaluation() {
		
		console.log(formData)
		const obj = {...formData}
        if (!formData.id) {
            obj.id = RandomString(14);
        }
		if (obj.custom) {
            obj.source = obj.customSource
            delete obj.customSource
            obj.itemId = `${obj.source}-c${obj.id}`
        } else {
            for ( let r of resultList ) {
                console.log(r)
                if (r.id === formData.itemId) {
                    obj.item = r
                    break;
                }
            }
        }
        console.log(obj)
        // return;
		
		obj.ts = new Date().getTime();
		console.log(obj)
		const res = await UseCloudFunction('saveAdminEval',{'obj':obj})
		console.log(res)
		if (res.message === 'eval saved successfully') {
			UIkit.modal.alert('Evaluation saved successfully!')
			.then(() => {
				setFormData(null);
				dispatch(setEvalList({
					...evalList, 
					...{
						[obj.id]: {
							'cpdu': obj.cpdu,
							'hours': obj.hours,
							'id': obj.id,
							'title': obj.item.title,
							'ts': obj.ts,
							'type': obj.type,
                            'source': obj.source
						}
					}
				}))
                const searchText = document.getElementById('search-text');
                if (searchText) {
                    searchText.value = ''
                }
			})
		} else if (res.message === 'eval already exists') {
			UIkit.modal.alert('An evaluation already exists for this event.')
		} else {
			UIkit.modal.alert('Something went wrong...')
		}
	}

	return (
		<div className='new-eval-form'>
			<div className="uk-margin">
				<label>Choose a Source</label>
				<select className="uk-select" aria-label="Select a Source" value={(formData && formData.source) ? formData.source : ''} onChange={(e) => saveData(e.target.value, 'source')}>
					<option value=''>Choose...</option>
					{
						(Sources)
						?
						Object.values(Sources)
						.sort((a,b) => (a.title > b.title) ? 1 : -1)
						.map(source => (
							<option key={source.id} value={source.id}>{source.title}</option>
						))
						: ''
					}
                    <option value='custom'>Custom Evaluation</option>
				</select>
			</div>

            {
                (!formData || (formData && !formData.custom && formData.source !== 'coach')) &&
                <div>
                    <div className="uk-margin">
                        
                        {
                            (formData && (formData.source === 'bridge')) &&
                            <>
                            <label>Find an Event to Evaluate</label>
                            <input className="uk-input" id='search-text' aria-label="Search for an Event to Evaluate" />
                            </>
                        }
                        <button className='g-button button-small' id='search-button' onClick={() => search()}>Search</button>
                    </div>
                
                    {
                        (resultList && (!formData || !formData.itemId))
                        ?
                        <div className='result-card result-list' id='search-results'>
                            {
                                resultList.map(r => (
                                    <div key={r.id} className='result-list-item' id={r.id} onClick={(e) => chooseItem(e.target.id)}>
                                        {r.title}
                                    </div>		
                                ))
                            }
                            {
                                (nextUrl)
                                ?
                                <div className='result-list-item' onClick={() => getMoreResults()}><b>Load more...</b></div>
                                : ''
                            }
                            
                        </div>
                        : ''
                    }
                </div>
            }
            
            
			{
				(formData && ((formData.itemId && formData.item) || formData.source === 'custom' || formData.source === 'coach'))
				?
				<>
                    {
                        (!formData.custom && formData.item) &&
                        <div className='uk-margin-large'>
                            <h4><b>{formData.item.title}</b></h4>
                            <p>{formData.item.description}</p>
                        </div>
                    }
					
                    {
                        (formData.custom || formData.source === 'coach') &&
                        <div>
                            <div className='uk-margin'>
                                <label>Title</label><br />
                                <input className="uk-input" type='text' id='title' aria-label="Name of Presenter" value={(formData && formData.item && formData.item.title) ? formData.item.title : ''} onChange={(e) => saveData(e.target.value, 'title')} />
                            </div>
                            {
                                (formData.source !== 'coach') &&
                                <div className='uk-margin'>
                                    <label>Type of Evaluation</label><br />
                                    <select className="uk-select" aria-label="Select a Source" value={(formData && formData.customSource) ? formData.customSource : ''} onChange={(e) => saveData(e.target.value, 'customSource')}>
                                        <option value=''>Choose...</option>
                                        {
                                            (Sources)
                                            ?
                                            Object.values(Sources)
                                            .sort((a,b) => (a.title > b.title) ? 1 : -1)
                                            .map(source => (
                                                <option key={source.id} value={source.id}>{source.title}</option>
                                            ))
                                            : ''
                                        }
                                    </select>
                                </div>
                            }   
                            <div className='uk-margin'>
                                <label>Description</label><br />
                                <input className="uk-input" type='text' id='description' aria-label="Name of Presenter" value={(formData && formData.item && formData.item.description) ? formData.item.description : ''} onChange={(e) => saveData(e.target.value, 'description')} />
                            </div>
                        </div>
                    }
                    
                    {
                        (formData.source !== 'coach') &&
                        <div className='uk-margin'>
                            <label>Presenter(s)</label><br />
                            <input className="uk-input" type='text' id='presenters' aria-label="Name of Presenter" value={(formData && formData.pres) ? formData.pres : ''} onChange={(e) => saveData(e.target.value, 'pres')} />
                        </div>
                    }

					
					<div className="uk-margin">
						<label>Choose Form Style</label>
						<select className="uk-select" aria-label="Select a Form Type" value={(formData && formData.type) ? formData.type : ''} onChange={(e) => saveData(e.target.value, 'type')}>
							<option value=''>Choose...</option>
							{
								(FormTypes)
								?
								Object.values(FormTypes)
								.sort((a,b) => (a.title > b.title) ? 1 : -1)
								.map(type => (
									<option key={type.id} value={type.id}>{type.title}</option>
								))
								: ''
							}
							
							
						</select>
					</div>

					
	
					
					{	
						(formData && formData.type)
						?
						<div className='uk-margin'>
							<table>
								<tbody>
									<tr>
										<td className='checkbox-td'>
											<input className="uk-checkbox" type="checkbox" defaultChecked={false} onClick={(e) => saveData(e.target.checked, 'cpdu')} />
										</td>
										<td>Send Evidence Completion document after completion</td>
									</tr>
								</tbody>
							</table>
						</div>
						: ''
					}
					{
						(formData && formData.cpdu)
						?
						<div className='uk-margin'>
							<label>Number of Hours for Evidence</label><br />
							<input className="uk-input uk-form-width-small" type='number' id='hours' aria-label="Number of hours" value={(formData && formData.hours) ? formData.hours : 0} onChange={(e) => saveData(e.target.value, 'hours')} />
						</div>
						: ''
					}
				</>
				: ''
			}
            {
                (loading) &&
                <div className='loader'>
                    <div data-uk-spinner='ratio: 2'></div>
                </div>
            }
			{
				(formComplete)
				?
				<div className='buttons'>
					<button className='g-button blue' onClick={() => saveEvaluation()}>Save Evaluation</button>
				</div>
				: ''
			}
		</div>
	)
}

export default CreateEvalForm;