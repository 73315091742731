import React, { useEffect, useState } from 'react'
import './homepage.styles.scss'

import { Routes, Route, Navigate } from 'react-router-dom'

import Header from '@/components/header/header.component'
// import Header from '@/components/header/header.component'
import HomeContent from '@/components/HOME/home-content/home-content.component'
import MyEvalsList from '@/components/HOME/home-content/my-evals-list.component'
import Footer from '@/components/footer/footer.component'
import LandingContent from '@/components/landing-content/landing-content.component'
import { Sources } from '@/forms/formTypes'


const HomePage = () => {

	const [ source, setSource ] = useState(null)
	
	useEffect(() => {
		const header = document.getElementsByClassName('header')[0]
		console.log('source', source)
		if (source && source !== 'eln' && source !== 'ipa') {
			const sourceCat = Sources[source].cat;
			header.classList.add(sourceCat)		
			setSource(sourceCat)
		} else if (source) {
			header.classList.add(source)
		} else {
			header.classList.remove('eln')
			header.classList.remove('ipa')
		}
		
		
	}, [source])
	
	return (
		<div className='homepage'>
			<Header source={source} />
			<Routes>
				<Route path='' element={<Navigate to="/" />} ></Route>
				<Route path='eln' element={
					<MyEvalsList setSource={setSource} type={'eln'} />
				}></Route>
				<Route path='ipa' element={
					<MyEvalsList setSource={setSource} type={'ipa'} />
				}></Route>
			</Routes>
			
			<Footer source={source} />
		</div>
	)
}

export default HomePage