import React, { useEffect, useState } from 'react'
import './eval-data.styles.scss'

import { useSelector } from 'react-redux'
import { setSelectedTabs } from '@/utils/tabs'

import { ref, child, get } from "firebase/database";
import { doc, getDoc } from "firebase/firestore";
import { rtDb, db } from '@/utils/firebase.utils'

import { FormTypes, CombineForms } from '@/forms/formTypes'

const EvalData = () => {

	const evals = useSelector(state => state.listsData.evalList);
	const [ evalData, setEvalData ] = useState(null);
	const [ formContent ] = useState(CombineForms());
	const [ displayData, setDisplayData ] = useState(null);
	const [ categories, setCategories ] = useState(null)
	
	useEffect(() => {
		setTimeout(() => {
            setSelectedTabs('getData')
        }, 10)
	}, [])

	async function getEvalData(id) {
		const submittedEvals = await get(child(ref(rtDb), `evals/${id}`)).then((snapshot) => {
			return snapshot.exists() ? snapshot.val() : false;
		}).catch((error) => {
			console.error(error);
		});
		const docRef = doc(db, "evals", id);
		const savedEvalData = await getDoc(docRef).then((doc) => {
			return (doc.exists) ? doc.data() : false;
		})
		// console.log(submittedEvals);
		// console.log(savedEvalData);
		setEvalData({...savedEvalData, ...{'evals': submittedEvals}})
	}

	useEffect(() => {
		if (!evalData || !FormTypes || !formContent) { return; }
		// console.log(evalData)
		const form = FormTypes[evalData.type]
		// console.log(form)
		let formCategories = []
		for (let c of form.comps) {
			// console.log(c)
			for (let s of Object.values(formContent[c].sections)) {
				// console.log(s)
				formCategories.push(s)
			}
			
		}
		console.log(formCategories)
		setCategories(formCategories)
		const completeData = {}
		for (let e of Object.values(evalData.evals)) {
			for (let c of formCategories) {
				completeData[c.key] = {
					title: c.title,
					key: c.key,
					type: c.type,
				}
				if (c.type === 'multiple') {
					completeData[c.key].content = {}
					for (let b of Object.values(c.content)) {
						completeData[c.key].content[b.key] = {
							title: b.text,
							key: b.key,
							data: {
								total: 0,
							}
						}
					}	
				} else if (c.type === 'textarea') {
					completeData[c.key].data = {
						comments: [],
					}
				} else if (c.type === 'select') {
					completeData[c.key].data = {
						total: 0,
						avg: 0
					}
				}
			}
		}
		console.log(completeData)
		getEvalContent(completeData)
	}, [evalData, FormTypes, formContent ])

	function getEvalContent(completeData) {
		console.log(evalData)
		let evalContent = {}
		for (let e of Object.values(evalData.evals)) {
			for (let f of Object.keys(e)) {
				if (f === 'evalId' || f === 'evalItemId' || f === 'evalSource' || f === 'evalType') {
					continue;
				}
				if (evalContent[f]) {
					evalContent[f].push(e[f])
				} else {
					evalContent[f] = [e[f]]
				}
			}
		}
		// console.log(evalContent)
		getDisplayData(completeData, evalContent)
	}

	async function getDisplayData(completeData, evalContent) {
		for (let e of Object.values(completeData)) {
			// console.log(e)
			if (e.type === 'multiple') {
				for (let c of Object.values(e.content)) {
					if (evalContent[c.key]) {
						completeData[e.key].content[c.key].data.total = await getSum(evalContent[c.key])
					}
				}
			} else if (e.type === 'select') {
				completeData[e.key].data.total = await getSum(evalContent[e.key])
				completeData[e.key].data.avg = await getAvg(evalContent[e.key])
			} else if (e.type === 'textarea') {
                completeData[e.key].data.comments = evalContent[e.key] ? evalContent[e.key] : []
            }
		}
		// console.log(completeData)
		setDisplayData(completeData)
	}

	function getSum(arr) {
		const initialValue = 0;
		return arr.reduce(
		  (accumulator, currentValue) => accumulator + currentValue,
		  initialValue
		);
	}

	async function getAvg(arr) {
		const sum = await getSum(arr)
		// console.log(sum)
		return (sum / Object.keys(evalData.evals).length).toFixed(1)
	}
	
	return (
		<div className='eval-data'>
			<div className='section-title'>
				<span className='allcaps'>Evaluation Data</span>
			</div>
			<div className='g-height-40'></div>
			<div className='data-section'>
				{/* <h3>Data</h3> */}
				<div className="uk-margin">
					<label>Choose an Evaluation</label>
		            <select className="uk-select" aria-label="Select an Evaluation" onChange={(e) => getEvalData(e.target.value)}>
		                <option value=''>Choose...</option>
						{
							(evals)
							?
							Object.values(evals)
							.sort((a,b) => (a.title.toLowerCase() > b.title.toLowerCase()) ? 1 : -1)
							.map(ev => (
								<option key={ev.id} value={ev.id}>{ev.title}</option>
							))
							: null
						}
		            </select>
		        </div>
				{
					(displayData) &&
					<div className='data-container'>
						<div className='data-header'>
                            <h3>Collected Data</h3>
                            <p>Evaluations: {Object.values(evalData.evals).length}</p>
                        </div>
                        {
                            (Object.values(evalData.evals).length > 0) &&
                            categories.map(cat => (
                                (cat.type === 'multiple') 
                                ?
                                <div key={cat.key} className='results-container'>
                                    <h4>{cat.title}</h4>
                                    <table className='uk-table uk-table-striped'>
                                        <tbody>
                                        {
                                            (displayData[cat.key]) &&
                                            Object.values(displayData[cat.key].content).map(m => (
                                                <tr key={m.key}>
                                                    <td>{m.title}</td>
                                                    <td className='total-td' width='80px'>{m.data.total}</td>
                                                </tr>
                                            ))
                                            
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                (cat.type === 'select') 
                                ?
                                <div key={cat.key} className='results-container'>
                                    {
                                        (displayData[cat.key]) &&    
                                        <>
                                            <h4>{cat.title}</h4>
                                            <table className='uk-table uk-table-striped'>
                                                <thead>
                                                    <tr>
                                                        <td className='select-td'>
                                                            Strongly Agree
                                                        </td>
                                                        <td className='select-td'>
                                                            Agree
                                                        </td>
                                                        <td className='select-td'>
                                                            Somewhat Agree
                                                        </td>
                                                        <td className='select-td'>
                                                            Disagree
                                                        </td>
                                                        <td className='select-td'>
                                                            Average
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className='select-td'>
                                                            {
                                                                Object.values(evalData.evals)
                                                                .filter(ev => ev[cat.key] === 4).length
                                                            }
                                                        </td>
                                                        <td className='select-td'>
                                                            {
                                                                Object.values(evalData.evals)
                                                                .filter(ev => ev[cat.key] === 3).length
                                                            }
                                                        </td>
                                                        <td className='select-td'>
                                                            {
                                                                Object.values(evalData.evals)
                                                                .filter(ev => ev[cat.key] === 2).length
                                                            }
                                                        </td>
                                                        <td className='select-td'>
                                                            {
                                                                Object.values(evalData.evals)
                                                                .filter(ev => ev[cat.key] === 1).length
                                                            }
                                                        </td>
                                                        <td className='select-td'>
                                                            {
                                                                displayData[cat.key].data.avg
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </>
                                    }
                                </div>
                                : 
                                (cat.type === 'textarea') 
                                ?
                                <div key={cat.key} className='results-container'>
                                    {
                                        (displayData[cat.key]) &&    
                                        <>
                                            <h4>{cat.title}</h4>
                                            <table className='uk-table uk-table-striped'>
                                                {
                                                    displayData[cat.key].data.comments.map(com => (
                                                        <tr key={com}>
                                                            <td>{com}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </table>
                                        </>
                                    }
                                </div>
                                :
                                ''
                            ))		
                        }
							
						
					</div>
				}
					
			</div>
			
		</div>
	)
}

export default EvalData;