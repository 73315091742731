import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import "./init";
import './index.css'
import "uikit/dist/css/uikit.min.css"
import Root from './App.jsx'
import { store } from './state/store'
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

const router = createBrowserRouter(
  [
    {
      path: '*',
      Component: Root
    },
  ]
)

createRoot(document.getElementById('root')).render(
  // <StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
        {/* <App /> */}      
    </Provider>
  // </StrictMode>,
)
