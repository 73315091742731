import React, { useEffect } from 'react'
import './logout.styles.scss'
import { auth, signOut } from '@/utils/firebase.utils'
import { useSelector, useDispatch } from 'react-redux'
import { setUserProfile, setCurrentUser, setDbData } from '@/state/slices/userSlice'
import { setEvalList } from '@/state/slices/listsSlice'

const Logout = () => {

	const dispatch = useDispatch();

  	useEffect(() => {
		async function startSignOut () {
			dispatch(setCurrentUser(null))
			dispatch(setDbData(null))
			dispatch(setUserProfile(null))
			dispatch(setEvalList(null))

			const signOutRes = await signOut()
			console.log(signOutRes.message)
			if (signOutRes.message === 'signed out') {
				// console.log(auth.currentUser)
				// let href = window.location.origin
				// // console.log(window.location)
				// let encoded = encodeURIComponent(href  + `/sso/?token={token}&intendedurl=%2Fhome%2F`)
				// // console.log(encoded)
				// window.open(`https://eweb.ilprincipals.org/eweb/?Site=ipa&WebCode=LoginRequired&expires=yes&URL_success=${encoded}`, '_self')
				window.open('https://edleadersnetwork.org', '_self');
			}
			// setTimeout(() => {
			// 	// window.open('https://ilprincipals.org/mentoring/', '_self')
			// }, 1000)
			// // window.open('https://eweb.ilprincipals.org/eweb/StartPage.aspx?logoff=y',"_blank")
		}
		startSignOut();
	}, [])
  
	return (
		<div className='logout-page'>
  			
		</div>
	)
}

export default Logout