import React, { useEffect } from 'react'
import './new-eval-modal.styles.scss'

import Modal from '@/components/modal/modal.component'
import NewEvalForm from '@/components/ADMIN/create-eval/create-eval-form.component'

const NewEvalModal = ({ close, show }) => {

	
	
	return (
		<div className='new-eval-modal'>
			<Modal  
				handleClose={close} 
				cancel={close} 
				show={show} 
				children={
					<NewEvalForm />
				} 
				text='Limit Results' 
				closeButton={true}
				cls={'narrow'}
				cls2={'modal-inner'} />
		</div>
	)
}

export default NewEvalModal;