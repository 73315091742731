import React, { useState, useEffect } from 'react'
import './admin.styles.scss'

import { Routes, Route } from 'react-router-dom'

import Header from '@/components/header/header.component'
import ManageEvals from '@/components/ADMIN/manage-evals/manage-evals.component'
import CreateEval from '@/components/ADMIN/create-eval/create-eval.component'
import EvalData from '@/components/ADMIN/eval-data/eval-data.component'
import Footer from '@/components/footer/footer.component'

import { useSelector, useDispatch } from 'react-redux'
import { setEvalList } from '@/state/slices/listsSlice'

import { ref, child, get } from "firebase/database";
import { rtDb } from '@/utils/firebase.utils'

const AdminPage = () => {

	const evalList = useSelector(state => state.listsData.evalList)
	const dispatch = useDispatch()
	const [ tabs, setTabs ] = useState(null)
	const [ source ] = useState('none')

	useEffect(() => {
		
		console.log('running useEffect')
		// updateLists();
        getEvalsFromDB();
		setTabs(
			[
				{
					'id': 'createEvals',
					'title': 'Create an Evaluation',
					'href': '/admin',
					'selected': 'selected'
				},
				{
					'id': 'manageEvals',
					'title': 'Manage Evaluations',
					'href': '/admin/evals',
					'selected': 'not_selected'
				},
				// {
				// 	'id': 'manageModules',
				// 	'title': 'Manage Eval Modules',
				// 	'href': '/admin/cpdu',
				// 	'selected': 'not_selected'
				// },
				{
					'id': 'getData',
					'title': 'Get Eval Data',
					'href': '/admin/data',
					'selected': 'not_selected'
				},
			]
		)
	}, [])
	
	const getEvalsFromDB = async (e) => {
		get(child(ref(rtDb), `evalList`)).then((snapshot) => {
			if (snapshot.exists()) {
				dispatch(setEvalList(snapshot.val()))
			} else {
				console.log("No data available");
			}
		}).catch((error) => {
			console.error(error);
		});
		
	}

	
	
	return (
		<div className='admin-page'>
			<Header tabs={tabs} source={source} />
			<div className='admin-content g-container'>
				<Routes>
					<Route path='' element={<CreateEval />}></Route>
					<Route path='/evals' element={<ManageEvals />}></Route>
					<Route path='/data' element={<EvalData />}></Route>
				</Routes>
				
			</div>
			<Footer />
		</div>
	)
}

export default AdminPage