import React, { useEffect, useState } from 'react'
import './home-content.styles.scss'

import { useParams, useNavigate } from 'react-router-dom'
import UIkit from 'uikit'
import IsbeForm from '@/forms/isbe.form.json'
import ElnForm from '@/forms/eln.form.json'
import Form from './form.component'
import { FormTypes } from '@/forms/formTypes'
import PdfDisplay from './pdf-display.component'

import { useSelector } from 'react-redux'

import { UseCloudFunction } from '@/utils/firebase.utils'

const HomeContent = ({ setSource }) => {
	
	const { id } = useParams()
	const userProfile = useSelector(state => state.userData.userProfile);
	const [ noId, setNoId ] = useState(false)
	const [ evalData, setEvalData ] = useState(null)
	const [ formContent, setFormContent ] = useState(null)
	const [ fullForms, setFullForms ] = useState({
		"isbe": IsbeForm,
		"eln": ElnForm
	})
	const [ cpduData, setCpduData ] = useState(null)
	const navigate = useNavigate();

	useEffect(() => {
		// console.log(id)
		if (!id) {
			setNoId(true)
			// console.log('checking for id')
			return;
		}
	}, [])
	
	useEffect(() => {
		if (!id) {
			setNoId(true)
			return;
		}
		setTimeout(() => {
            getEvalDoc(id);
        }, 700);
	}, [id]);

	async function getEvalDoc(id) {
        if (!userProfile) {
            console.log('no user profile');
            navigate(`/${id}`);
            return;
        }
		const getEval = await UseCloudFunction(
			'getEvalCheckCompletion',
			{
				'cst_key': userProfile.u_cst_key,
				'evalId': id
			}
		)
		if (getEval.evalData) {
			setEvalData(getEval.evalData);
			setSource(getEval.evalData.source)
			// console.log(getEval.evalData)
			// console.log(FormTypes[getEval.evalData.type])
			let formParts = []
			for (let c of FormTypes[getEval.evalData.type].comps) {
				formParts = [...formParts, ...Object.values(fullForms[c].sections)]
			}
			// console.log(formParts)
			// for ()
			setFormContent(formParts)
		} else {
		  	setNoId(true);
		  	if (getEval.message === 'already completed') {
				UIkit.modal.alert('Sorry, you have already completed this evaluation. Check your list of completed evaluations to make sure this is correct.')
				.then(() => {
					navigate('/home')
				})
				
			} if (getEval.message === 'no eval exists') {
				UIkit.modal.alert('Sorry, this evaluation link does not match with any of our exisiting evaluations.')
				.then(() => {
					navigate('/home')
				})
			}
		}
	}
	
	return (

		<div className='landing-content'>
			{
				(evalData)
				?
				<div  className="form-container">
					<div className='section-title'>
						<span className='allcaps'>Evaluation: {evalData.item.title}</span>
					</div>
					<h4>
						<b>Title:</b> {evalData.item.title}<br />
						<b>Training Type:</b> {
							(evalData.source === 'bridge')
							? 'ELN Course'
							: (evalData.source === 'aa')
							? 'Admin Academy'
							: (evalData.source === 'mc')
							? 'Micro-Credential'
							: (evalData.source === 'zoomWebinar')
                            ? 'Zoom Webinar'
                            : ''
						}
					</h4>
					<hr />
					{
						(formContent)
						?
						<div>
							<Form evalData={evalData} formContent={formContent} setFormContent={setFormContent} userProfile={userProfile} setCpduData={setCpduData} />
						</div>
						: 
						<div>
							<PdfDisplay cpduData={cpduData} source={evalData.source} />
						</div>
					}
				</div>
				: ''
			}
			
			
		</div>
	)
}

export default HomeContent;