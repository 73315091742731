import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from './userSlice';

const initialState = {
  evalList: null,
}

export const listsSlice = createSlice({
  name: 'lists',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setEvalList: (state, action) => {
      state.evalList = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  setEvalList, 
} = listsSlice.actions

export default listsSlice.reducer